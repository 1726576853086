
import { Component, Vue, Prop } from 'vue-property-decorator';
import { translateApi } from '../utils/translate-api';
import env from '../../env.config';

@Component<SocialShare>({
  components: {},
  filters: {
    translateApi,
  },

  metaInfo() {
    return {
      title: this.seoTitle,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.seoDescription,
        },
        {
          vmid: 'image',
          property: 'og:image',
          content: this.image,
        },
      ],
      link: [
        {
          rel: 'canonical',
          // hreflang: this.localeCode,
          href: `${env.VUE_APP_FRONT_END_URL}${this.$route.path}`,
        },
      ],
    };
  },
})
export default class SocialShare extends Vue {
  @Prop({ type: Object, required: true })
  course;
  @Prop({ type: String, required: true })
  image;
  get seoTitle() {
    if (this.course) {
      return translateApi(this.course.seoTitle, this.$i18n.locale);
    }
    return '';
  }

  get seoDescription() {
    if (this.course) {
      return translateApi(this.course.seoDescription, this.$i18n.locale);
    }
    return '';
  }
}
