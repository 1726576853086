
import { TranslatedProperty } from '@/models/shared/translated-property.class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { translateApi } from '../utils/translate-api';

@Component({
  components: {},
  filters: {
    translateApi,
  },
})
export default class FaqItem extends Vue {
  visible = false;

  @Prop({ type: Object, required: true })
  faq;

  getTranslatedContent(answer: TranslatedProperty) {
    const translatedAnswer = translateApi(answer, this.$i18n.locale);
    return translatedAnswer;
  }
}
