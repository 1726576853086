
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { Trainer } from '@/models/trainers/trainer.interface';

@Component({
  components: {},
})
export default class TrainerItem extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  trainer!: Trainer;

  @Emit('more-info')
  onMoreInfoClicked() {
    return true;
  }
}
