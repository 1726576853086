
import { Component, Vue, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { translateApi } from '../utils/translate-api';
import Course from '@/models/courses/course.class';
import Faq from '@/models/faqs/faq.class';
import FaqItem from '../components/FaqItem.vue';
import moment from 'moment';
import { BModal } from 'bootstrap-vue';
import { StaticContentType } from '../enums/static-content-type.enum';
import { StaticContent } from '../models/static-contents/static-content.interface';
import { Tailored as TailoredModel } from '../models/static-contents/tailored.interface';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';
import { TranslatedProperty } from '../models/shared/translated-property.class';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';
import env from '../../env.config';
import { RawLocation } from 'vue-router';
import { ContactOption } from '../enums/contact-option.enum';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { PaginatedResponse } from '@/models/shared/paginated-response.interface';
import { Filters } from '@/models/app/filters.interface';
import { FilterOperation } from '@/models/app/filter-operation.enum';
import Session from '../models/sessions/session.class';
import TrainerItem from '../components/TrainerItem.vue';
import { Trainer } from '@/models/trainers/trainer.interface';
import CustomerReviews from '../components/CustomerReviews.vue';
import CustomerReview from '@/models/customer-reviews/customer-review.class';
import SocialShare from '../components/SocialShare.vue';

const coursesModule = namespace('courses');
const faqsModule = namespace('faqs');
const appModule = namespace('app');
const staticContentsModule = namespace('staticContents');
const customerReviewsModule = namespace('customerReviews');

@Component<CourseDetail>({
  components: {
    TrainerItem,
    FaqItem,
    CustomerReviews,
    SocialShare,
  },
  filters: {
    translateApi,
  },
})
export default class CourseDetail extends Mixins(UsePageHeaderMixin, UseCurrentLocaleMixin) {
  @coursesModule.Getter('one')
  course!: Course;

  @coursesModule.Action('fetchOne')
  fetchCourseById!: (id: string) => Promise<Course>;

  @coursesModule.Getter('futureSessions')
  futureSessions!: Session[];

  @coursesModule.Action('fetchFutureSessions')
  fetchFutureSessions!: (id: string) => Session[];

  @coursesModule.Action('clearOne')
  clearOne!: () => void;

  @appModule.Getter('windowWidth')
  viewWidth!: number;

  @faqsModule.Action('FETCH_ALL')
  fetchFaqs!: (filter?: FetchAllParams) => PaginatedResponse<Faq>;

  @faqsModule.Getter('ALL_PAGINATED')
  faqs!: Faq[];

  @staticContentsModule.Action('fetchStaticContent')
  fetchStaticContent!: (type: StaticContentType) => Promise<StaticContent>;

  @staticContentsModule.Getter('getStaticContentByType')
  getStaticContentByType!: (type: StaticContentType) => TailoredModel;

  @customerReviewsModule.Action('fetchUnpaginated')
  fetchCustomerReviews!: (params?: FetchAllParams) => CustomerReview[];

  @customerReviewsModule.Getter('all')
  customerReviews!: CustomerReview[];

  i = 0;

  scrollable = false;

  expanded = '';

  tailored: TailoredModel | null = null;

  visible = true;

  hover = false;

  $refs!: Vue['$refs'] & {
    trainerModal: BModal;
  };

  modalTrainer: Trainer | null = null;

  get headerHtml() {
    if (this.course) {
      return `<h3 class="text-white">${translateApi(this.course.title, this.$i18n.locale)}</h3>`;
    } else return '';
  }

  get imageUrl() {
    if (this.course) {
      return this.course.banner.url;
    }
    return '';
  }

  get locationString() {
    const selectedSession = this.futureSessions[this.i];

    if (selectedSession && selectedSession.location) {
      return `${translateApi(
        selectedSession.location.name,
        this.$i18n.locale,
      )}, <br/>${translateApi(selectedSession.location.address, this.$i18n.locale)}, <br />
      ${selectedSession.location.zipCode} ${translateApi(
        selectedSession.location.city,
        this.$i18n.locale,
      )}`;
    }

    return '';
  }

  get sessionInfo() {
    const selectedSession = this.futureSessions[this.i];
    return translateApi(selectedSession?.info, this.$i18n.locale);
  }

  get sessionFullyBooked() {
    const selectedSession = this.futureSessions[this.i];
    return selectedSession.maxStudents <= selectedSession.registrationCount;
  }

  checkViewWidth(key: string) {
    if (this.viewWidth > 575 || this.expanded === key) {
      this.expanded = '';
    } else {
      this.expanded = key;
    }
  }

  goToContent(content: string) {
    this.$router
      .push({
        path: `${this.localeBaseUrlPath}/courses/${this.$route.params.slug}#${content}`,
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
      .catch(err => {});
  }

  getTranslation(element: TranslatedProperty) {
    const res = translateApi(element, this.$i18n.locale);
    return res;
  }

  formattedDate(date: string) {
    moment.locale('nl-be');
    if (!date) {
      return '';
    }
    return moment(date).format('L');
  }

  get trainerDescription() {
    return translateApi(this.modalTrainer?.description, this.$i18n.locale);
  }

  changeSession(index: number) {
    this.i = index;
  }

  openTrainersModal() {
    this.$refs.trainerModal.show();
  }

  onTrainerMoreInfo(trainerIndex: number) {
    this.modalTrainer = this.course.trainers[trainerIndex];
    this.openTrainersModal();
  }

  goToContactPage() {
    const route: RawLocation = {
      name: 'contact',
      params: {
        locale: this.localeCode,
        subject: ContactOption.TailorMade,
        courseTitle: translateApi(this.course.title, this.$i18n.locale),
      },
    };

    if (this.localeCode === 'nl') {
      delete route.params?.locale;
    }

    this.$router.push(route);
  }

  async created() {
    const course = await this.fetchCourseById(this.$route.params.slug);

    if (!this.course || !this.course._id) {
      this.$router.push({ name: 'pageNotFound' });
    }

    if (this.course && this.course._id) {
      await this.fetchFutureSessions(this.course._id);
      const preSelectedSessionId = this.$route.params.selectedSessionId;
      if (preSelectedSessionId) {
        this.i = this.futureSessions.findIndex(session => session._id === preSelectedSessionId);
      }

      const filters: Filters = {
        courses: { value: [course._id], operation: FilterOperation.Equals },
      };
      const params: FetchAllParams = { filters: filters };
      await this.fetchFaqs(filters);
      await this.fetchCustomerReviews(params);
    }

    if (!this.getStaticContentByType(StaticContentType.Tailored)) {
      await this.fetchStaticContent(StaticContentType.Tailored);
    }
    this.tailored = this.getStaticContentByType(StaticContentType.Tailored);
  }

  destroyed() {
    this.clearOne();
  }
}
